






































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment-timezone';
import DIVFilter from '@/shared/components/filters/DIVFilter.vue';
import SelectionFilter from '@/shared/components/form/SelectionFilter.vue';

import { ReportsModule } from '@/store/index';
import {
  BS_DATEPICKER_FORMAT,
  convertDateFieldToAPIFormatWithoutTime,
  convertDateFieldToAPIFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';

import {
  FilterInput,
  FilterConditions
} from '@/shared/services/filter/filter.service';

import { ReportsType } from '../models';
import { CompanyService } from '@/shared/services/mater-data/company.service';
import { reportsBus } from '../Reports.vue';
import { DivisionModule } from '@/store/index';
import { NumberType } from '@/pages/Dispatch/models/filters/number-filter.model';

@Component({ components: { DIVFilter, SelectionFilter } })
export default class ReportsFilters extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.search();
  }

  @Prop() selectedReportType: string;

  reportsModule = ReportsModule;
  reportsType = ReportsType;

  categories = [
    { value: null, text: 'ALL' },
    { value: 'IMP', text: 'IMP' },
    { value: 'EXP', text: 'EXP' },
    { value: 'VAN', text: 'VAN' },
    { value: 'CTY', text: 'CY TO CY' },
    { value: 'CHS', text: 'BARE CHASSIS' }
  ];

  selectList = [
    { value: 'billTo', text: this.$i18n.t('mainFilters.billTo') },
    { value: 'orderNumber', text: this.$i18n.t('mainTable.wo') },
    {
      value: 'containerNumber',
      text: this.$i18n.t('order.cont')
    },
    { value: 'shippingLine', text: this.$i18n.t('order.ssl') }
  ];

  selectionTypes = {
    list: [
      { value: NumberType.OrderNumber, text: this.$i18n.t('mainFilters.wo') },
      {
        value: NumberType.ContainerNumber,
        text: this.$i18n.t('mainFilters.containerNumber')
      },
      { value: NumberType.ShippingLine, text: this.$i18n.t('order.ssl') },
      { value: NumberType.BookingNumber, text: this.$i18n.t('order.mblBk') }
    ],
    autocompleteList: [NumberType.BookingNumber]
  };

  dateModel = this.currentDate;
  dateFromModel = moment()
    .subtract(2, 'weeks')
    .format(DATE_API_FORMAT);
  dateToModel = this.currentDate;
  dateMonthModel = moment().format('MM');
  dateYearModel = moment().year();
  categoryModel = this.categories[0].value;
  selectModel = {
    type: this.selectList[0].value,
    text: ''
  };
  selectLabel = 'BILL TO';

  statusFilter = {
    incomplete: new FilterInput({
      condition: FilterConditions.Equal,
      search: 'INCOMPLETE',
      field: 'status'
    }),
    complete: new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: 'status'
    }),
    solved: new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: 'status'
    })
  };

  divFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: DivisionModule.division,
    field: 'division'
  });

  typeFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: NumberType.OrderNumber
  });

  companyService = CompanyService;

  statusSolved = false;
  statusComplete = false;
  statusIncomplete = true;

  constructor() {
    super();
  }

  get currentDate() {
    return moment().format(DATE_API_FORMAT);
  }

  get listType() {
    return (
      this.$route.params.type == ReportsType.DEMURRAGE ||
      this.$route.params.type == ReportsType.PERDIEM
    );
  }

  get statisticsType() {
    return this.$route.params.type == ReportsType.STATISTICS;
  }

  get earlyWarningType() {
    return this.$route.params.type == ReportsType['EARLY-WARNING'];
  }

  get dateComparison() {
    return this.dateFromModel > this.dateToModel;
  }

  search() {
    this.reportsModule.setReportType(this.getReportType());
    this.reportsModule.setFilters([...this.generateFilters()]);

    if (this.statisticsType) {
      this.reportsModule.loadStatistics();
    } else if (this.earlyWarningType) {
      this.reportsModule.loadEarlyWarning();
    } else {
      this.reportsModule.search();
    }
  }

  selectChange(data) {
    this.selectLabel = this.selectList.filter(item => item.value === data)[0]
      .text as string;
  }

  email() {
    reportsBus.$emit('sendEmail');
  }

  generateFilters() {
    const filtersList = [this.divFilter];

    if (this.listType || this.earlyWarningType) {
      filtersList.push(
        this.createSelectFilter(),
        this.createDateToFilter(),
        this.createDateFromFilter()
      );

      if (this.earlyWarningType) {
        filtersList.push(
          this.statusFilter.incomplete,
          this.statusFilter.complete,
          this.statusFilter.solved,
          this.typeFilter
        );
      }
    } else if (this.statisticsType) {
      if (this.reportsModule.statisticsPeriod == 'weekly') {
        filtersList.push(this.createDateFilter());
      } else if (this.reportsModule.statisticsPeriod == 'monthly') {
        filtersList.push(this.createDateMonthlyFilter());
      } else {
        filtersList.push(this.createDateYearlyFilter());
      }
    } else {
      filtersList.push(this.createCategoryFilter(), this.createDateFilter());
    }

    return filtersList;
  }

  async excel() {
    this.reportsModule.setFilters([...this.generateFilters()]);

    await ReportsModule.excel(
      ReportsType[this.$route.params.type.toUpperCase()]
    );

    if (ReportsModule.excelData) {
      window.open(ReportsModule.excelData.url);
    }
  }

  private createCategoryFilter() {
    return new FilterInput({
      condition: FilterConditions.Equal,
      search: this.categoryModel,
      field: 'category'
    });
  }

  private createDateFilter() {
    return new FilterInput({
      condition: FilterConditions.Equal,
      search: this.dateModel ? this.dateModel : null,
      field: 'date'
    });
  }

  private createDateFromFilter() {
    return new FilterInput({
      condition: FilterConditions.Greater,
      search: moment(this.dateFromModel, [
        BS_DATEPICKER_FORMAT,
        DATE_API_FORMAT
      ])
        .startOf('day')
        .format(DATE_API_FORMAT),
      field: 'date'
    });
  }

  private createDateToFilter() {
    return new FilterInput({
      condition: FilterConditions.LessThenOrEqual,
      search: moment(this.dateToModel, [BS_DATEPICKER_FORMAT, DATE_API_FORMAT])
        .endOf('day')
        .format(DATE_API_FORMAT),
      field: 'date'
    });
  }

  private createDateMonthlyFilter() {
    return new FilterInput({
      condition: FilterConditions.Equal,
      search:
        this.dateYearModel && this.dateMonthModel
          ? convertDateFieldToAPIFormatWithoutTime(
              `${this.dateYearModel}${this.dateMonthModel}`
            )
          : null,
      field: 'date'
    });
  }

  private createSelectFilter() {
    return new FilterInput({
      condition: FilterConditions.BeginsWith,
      search: this.selectModel.text?.trim(),
      field: this.selectModel.type
    });
  }

  private createDateYearlyFilter() {
    return new FilterInput({
      condition: FilterConditions.Equal,
      search: this.dateYearModel
        ? convertDateFieldToAPIFormat(`${this.dateYearModel}`)
        : null,
      field: 'date'
    });
  }

  private getReportType(): ReportsType {
    switch (this.$route.params.type) {
      case ReportsType.PULLOUT:
        return ReportsType.PULLOUT;
      case ReportsType.DELIVERY:
        return ReportsType.DELIVERY;
      case ReportsType.RETURN:
        return ReportsType.RETURN;
      case ReportsType.DEMURRAGE:
        return ReportsType.DEMURRAGE;
      case ReportsType.PERDIEM:
        return ReportsType.PERDIEM;
      case ReportsType.STATISTICS:
        return ReportsType.STATISTICS;
      case ReportsType['EARLY-WARNING']:
        return ReportsType['EARLY-WARNING'];
      default:
        throw new Error(
          `Report type '${this.$route.params.type}' is not supported`
        );
    }
  }
}
