import { i18n } from '@/i18n';
import { CellContentType } from './../../../shared/components/table/models/cellContent.model';
import { ReportsType } from './reports.model';

export const ReportsListType = {
  [ReportsType.PULLOUT]: [
    { key: 'no', label: i18n.tc('mainTable.no') },
    { key: 'orderNumber', label: i18n.tc('mainTable.wo') },
    { key: 'billTo', label: i18n.tc('order.billTo') },
    { key: 'masterBillOfLading', label: i18n.tc('order.mblBk') },
    { key: 'shippingLine', label: i18n.tc('order.ssl') },
    { key: 'containerNumber', label: i18n.tc('order.cont') },
    { key: 'containerSize', label: i18n.tc('order.containerSize') },
    { key: 'containerType', label: i18n.tc('order.containerType') },
    { key: 'chassisNumber', label: i18n.tc('order.chassisNumber') },
    { key: 'chassisPool', label: i18n.tc('order.chassisPool') },
    { key: 'lastFreeDay', label: i18n.tc('order.lfd') },
    { key: 'avaDsc', label: i18n.tc('order.dsc') },
    { key: 'avaObl', label: i18n.tc('order.obl') },
    { key: 'avaCus', label: i18n.tc('order.cus') },
    { key: 'tmf', label: i18n.tc('mainTable.tmf') },
    { key: 'pulloutLocation', label: i18n.tc('mainTable.pOutLoc') },
    { key: 'pulloutSchedule', label: i18n.tc('mainTable.pOutSche') },
    { key: 'driverId', label: i18n.tc('mainTable.drv') },
    { key: 'deliverySchedule', label: i18n.tc('mainTable.dilvSche') },
    { key: 'city', label: i18n.tc('scheduleTable.city') },
    { key: 'state', label: i18n.tc('mainTable.state') },
    { key: 'weight', label: i18n.tc('order.weight') },
    { key: 'returnLocation', label: i18n.tc('mainTable.rtn') },
    { key: 'remarks', label: i18n.tc('order.remarks') }
  ],

  [ReportsType.DELIVERY]: [
    { key: 'no', label: i18n.tc('mainTable.no') },
    { key: 'orderNumber', label: i18n.tc('mainTable.wo') },
    { key: 'billTo', label: i18n.tc('order.billTo') },
    { key: 'shippingLine', label: i18n.tc('order.ssl') },
    { key: 'deliveryNumber', label: i18n.tc('order.deliveryNumber') },
    { key: 'containerNumber', label: i18n.tc('order.cont') },
    { key: 'containerSize', label: i18n.tc('order.containerSize') },
    { key: 'containerType', label: i18n.tc('order.containerType') },
    { key: 'returnFreeDay', label: i18n.tc('order.rfd') },
    { key: 'pulloutActualIn', label: i18n.tc('mainTable.pOutInActual') },
    { key: 'deliveryLocation', label: i18n.tc('mainTable.dilvLoc') },
    { key: 'city', label: i18n.tc('scheduleTable.city') },
    { key: 'state', label: i18n.tc('mainTable.state') },
    { key: 'deliverySchedule', label: i18n.tc('mainTable.dilvSche') },
    { key: 'dropLive', label: i18n.tc('mainTable.dropLive') },
    { key: 'driverIdDelivery', label: i18n.tc('mainTable.drvDilv') },
    { key: 'driverIdCurrent', label: i18n.tc('mainTable.drvCurr') },
    { key: 'returnLocation', label: i18n.tc('mainTable.rtnLoc') },
    { key: 'remarks', label: i18n.tc('order.remarks') }
  ],

  [ReportsType.RETURN]: [
    { key: 'no', label: i18n.tc('mainTable.no') },
    { key: 'billTo', label: i18n.tc('order.billTo') },
    { key: 'shippingLine', label: i18n.tc('order.ssl') },
    { key: 'containerNumber', label: i18n.tc('order.cont') },
    { key: 'containerSize', label: i18n.tc('order.containerSize') },
    { key: 'containerType', label: i18n.tc('order.containerType') },
    { key: 'chassisNumber', label: i18n.tc('order.chassisNumber') },
    { key: 'chassisPool', label: i18n.tc('order.chassisPool') },
    { key: 'bookingNumber', label: i18n.tc('mainTable.booking') },
    { key: 'erd', label: i18n.tc('order.erd') },
    { key: 'cutOff', label: i18n.tc('order.cutOff') },
    { key: 'returnFreeDay', label: i18n.tc('order.rfd') },
    { key: 'pulloutActualIn', label: i18n.tc('mainTable.pOutInActual') },
    { key: 'deliveryLocation', label: i18n.tc('mainTable.dilvLoc') },
    { key: 'deliveryActualIn', label: i18n.tc('mainTable.dilvOutActual') },
    { key: 'pulloutLocation', label: i18n.tc('mainTable.pOutLoc') },
    { key: 'returnLocation', label: i18n.tc('mainTable.rtnLoc') },
    { key: 'returnSchedule', label: i18n.tc('mainTable.rtnSche') },
    { key: 'driverId', label: i18n.tc('mainTable.drv') },
    { key: 'remarks', label: i18n.tc('order.remarks') }
  ],

  [ReportsType.DEMURRAGE]: [
    // { key: 'no', label: i18n.tc('mainTable.no') },
    { key: 'orderNumber', label: i18n.tc('mainTable.wo') },
    { key: 'billTo', label: i18n.tc('order.billTo') },
    { key: 'containerNumber', label: i18n.tc('order.cont') },
    { key: 'shippingLine', label: i18n.tc('order.ssl') },
    { key: 'lastFreeDay', label: i18n.tc('order.lfd') },
    { key: 'pulloutSchedule', label: i18n.tc('mainTable.pOutSche') },
    { key: 'avaDsc', label: i18n.tc('order.dsc') },
    { key: 'avaObl', label: i18n.tc('order.obl') },
    { key: 'avaCus', label: i18n.tc('order.cus') },
    { key: 'avaOth', label: i18n.tc('order.oth') },
    { key: 'accountResponsibility', label: i18n.tc('mainTable.ngl') },
    { key: 'customerResponsibility', label: i18n.tc('mainTable.cus') },
    { key: 'reason', label: i18n.tc('mainTable.reason') }
  ],

  [ReportsType.PERDIEM]: [
    // { key: 'no', label: i18n.tc('mainTable.no') },
    { key: 'category', label: 'Category' },
    { key: 'orderNumber', label: i18n.tc('mainTable.wo') },
    { key: 'containerNumber', label: i18n.tc('order.cont') },
    { key: 'shippingLine', label: i18n.tc('order.ssl') },
    { key: 'returnFreeDay', label: i18n.tc('order.rfd') },
    { key: 'billTo', label: i18n.tc('order.billTo') },
    { key: 'returnSchedule', label: i18n.tc('mainTable.rtnSche') },
    { key: 'returnLocation', label: i18n.tc('mainTable.rtnLoc') },
    { key: 'accountResponsibility', label: i18n.tc('mainTable.ngl') },
    { key: 'customerResponsibility', label: i18n.tc('mainTable.cus') },
    { key: 'reason', label: i18n.tc('mainTable.reason') }
  ],

  [ReportsType['EARLY-WARNING']]: [
    { key: 'number', label: i18n.tc('mainTable.no') },
    { key: 'createdDate', label: i18n.tc('mainFilters.createdDate') },
    { key: 'level', label: i18n.tc('mainTable.level') },
    { key: 'category', label: i18n.tc('mainTable.category') },
    { key: 'orderNumber', label: i18n.tc('mainTable.wo') },
    { key: 'containerNumber', label: i18n.tc('order.cont') },
    { key: 'warningIssue', label: i18n.tc('mainTable.warningIssue') },
    {
      key: 'status',
      label: i18n.tc('mainTable.status'),
      content: {
        type: CellContentType.Switcher,
        conditionalRender: (value: string) => value !== 'SOLVED'
      }
    },
    { key: 'updatedDate', label: i18n.tc('mainTable.updDate') },
    { key: 'time', label: i18n.tc('common.time') },
    { key: 'user', label: i18n.tc('mainTable.user') }
  ]
};
