
























































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import moment from 'moment-timezone';

import FullCalendar, {
  CalendarOptions,
  Calendar,
  EventClickArg
} from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { ScheduleListModal } from '../components';
import { ReportsModule } from '@/store/index';
import { ReportsType, ReportsListType } from '../models';
import { ReportsData } from '@/store/modules/reports/reports.model';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson,
  TransformFilterToPlain
} from '@/shared/services/filter/filter.service';
import {
  convertAPIToFormat,
  convertDateToFormat,
  DATE_API_FORMAT,
  DATE_API_FORMAT_WITHOUT_TIME
} from '@/utils/date.util';
import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import _ from 'lodash';

import Loading from '@/shared/components/Loading.vue';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: {
    FullCalendar,
    ScheduleListModal,
    WoBaseModal,
    Loading
  }
})
export default class CalendarReport extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    console.log('route');
    this.reportType = this.$route.params.type.toUpperCase();
  }

  @Watch('list')
  onListChanged() {
    const dateString: string = this.reportsModule.filters.find(
      field => 'date' === field.field
    )?.search;

    if (dateString) {
      this.fullCalendarRef.getApi().refetchEvents();
      this.fullCalendarRef.getApi().gotoDate(convertAPIToFormat(dateString));
    }
  }

  ReportsType = ReportsType;
  reportsModule = ReportsModule;
  reportType = '';
  navLinkDate = '';
  selectedWo = {};

  @Ref('fullCalendar') private fullCalendarRef: { getApi(): Calendar };

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridWeek',
    headerToolbar: { left: 'prev', center: 'title', right: 'next' },
    dayHeaderFormat: {
      weekday: 'short',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    eventClick: this.openReportDetailModal,
    navLinks: true,
    navLinkDayClick: this.openScheduleListModal,
    eventDisplay: 'block',
    eventTimeFormat: { hour: '2-digit', minute: '2-digit', meridiem: 'short' },
    eventColor: '#4D5255',
    eventOrder: '-timeText',
    locale: this.$i18n.locale === 'en' ? 'en' : 'es'
  };

  disabled = false;

  dateFrom: string;

  constructor() {
    super();
  }

  created() {
    this.disabled = PermissionsService.can('TMS REPORT', 'PO/DIL/RTN', 'View');
  }

  mounted() {
    console.log(this.fullCalendarRef);
  }

  get list(): [] {
    const reports = this.reportsModule.reports.map((event: ReportsData) => {
      return {
        title: `${event.category}`,
        id: event.orderNumber,
        start: moment(event.scheduleIn, DATE_API_FORMAT).format(),
        end: moment(event.scheduleIn, DATE_API_FORMAT).endOf('day').format(),
        sortDate: moment(event.scheduleIn, DATE_API_FORMAT).format(),
        extendedProps: {
          containerNumber: event.containerNumber,
          chassisNumber: event.chassisNumber,
          trailNumber: event.trailNumber,
          dropLive: event.dropLive,
          locationId: event.locationId,
          driverId: event.driverId,
          driverName: event.driverName,
          dateCompliance: this.getScheduleBG(event)
        }
      };
    });

    if (reports) {
      reports.forEach(el => {
        el.sortDate = moment(el.sortDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
      });

      const sortedDate = _.groupBy(reports, 'sortDate');
      const iterableArray = Object.values(sortedDate);

      iterableArray.map((el: any) =>
        el.map((item, index) => (item.no = ++index))
      );
    }
    return reports;
  }

  get selectedReportsList() {
    return ReportsListType[ReportsType[this.reportType]];
  }

  get currentDate() {
    return moment().format(DATE_API_FORMAT_WITHOUT_TIME);
  }

  get selectedDate(): string | null {
    return (
      this.reportsModule.filters.find(item => 'date' === item.field)?.search ??
      this.currentDate
    );
  }

  onCalendarClick(event) {
    if (this.isPrevCalendarButton(event.target)) {
      this.onPrevNextCalendarClick();
    } else if (this.isNextCalendarButton(event.target)) {
      this.onPrevNextCalendarClick();
    }
  }

  getScheduleBG({ scheduleIn, actualOut, actualIn }) {
    if (scheduleIn && !actualOut && !actualIn) {
      return 'schedule';
    }

    if (actualIn && !actualOut) {
      return 'actual-in';
    }

    if (actualOut) {
      return 'actual-out';
    }
  }

  openReportDetailModal(arg: EventClickArg) {
    this.selectedWo = this.reportsModule.reports.filter(
      (event: ReportsData) => event.orderNumber === arg.event.id
    )[0];

    this.$bvModal.show('wo-base-modal');
  }

  openScheduleListModal(date) {
    this.navLinkDate = moment(date).format('MM/DD/YY');
    const filters = [];

    const dateField = new FilterInput({
      condition: FilterConditions.Equal,
      search: moment(date).format(DATE_API_FORMAT),
      field: 'date'
    });

    filters.push(dateField);
    filters.push(
      ...this.reportsModule.filters.filter(
        filter => filter.field === 'division' || filter.field === 'category'
      )
    );

    this.reportsModule.loadOrders({
      reportType: ReportsType[this.reportType],
      filters: TransformFiltersToJson(TransformFilterToPlain([...filters]))
    });

    this.$bvModal.show('schedule-list-modal');
  }

  private isNextCalendarButton(element: HTMLElement): boolean {
    if (element) {
      if (
        element.classList.contains('fc-next-button') ||
        element.parentElement?.classList.contains('fc-next-button')
      ) {
        return true;
      }
    }

    return false;
  }

  private isPrevCalendarButton(element: HTMLElement): boolean {
    if (element) {
      if (
        element.classList.contains('fc-prev-button') ||
        element.parentElement?.classList.contains('fc-prev-button')
      ) {
        return true;
      }
    }

    return false;
  }

  private onPrevNextCalendarClick() {
    const currentDate = this.fullCalendarRef
      .getApi()
      .getCurrentData().currentDate;

    if (currentDate) {
      // TODO: Delete workaround when backend will handle correctly start and end week days
      const copyOfCurrentDay = new Date(currentDate.getTime());
      copyOfCurrentDay.setDate(copyOfCurrentDay.getDate() + 1);

      const newFilters = this.reportsModule.filters.map(filter => {
        if (filter.field === 'date') {
          const newFilter = new FilterInput({
            field: filter.field,
            condition: filter.condition,
            search: convertDateToFormat(
              copyOfCurrentDay,
              DATE_API_FORMAT_WITHOUT_TIME
            )
          });

          return newFilter;
        } else {
          return filter;
        }
      });

      this.reportsModule.setFilters(newFilters);
      this.reportsModule.search();
    }
  }
}
